/**
 * 角色相关的
**/
import request from '@/network/request.js'
/**
 * 添加用户时角色列表 /相当于所有的角色信息
 */
export function role_listAll (data){
	return request({
	  url: '/admin/user/allrolelists',
	  method: 'post',
	  data,
	})
}

/**
 * 角色对应的权限信息
 */

export function get_role (data){
	return request({
	  url: '/cadmin/api.role/get_role',
	  method: 'post',
	  data,
	})
}




