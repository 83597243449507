// 用户信息的表
import request from '@/network/request.js'
/**
 * 角色列表信息/分页
 */
export function powerlists (data){
	return request({
	  url: '/admin/user/lists_role',
	  method: 'post',
	  data,
	})
}


/**
 * 角色的状态修改
 */

export function srole_status (data){
	return request({
	  url: '/admin/user/rolestatus',
	  method: 'post',
	  data,
	})
}

/**
 * 删除用户信息
**/
export function del_power(data){
	return request({
	  url: '/admin/user/del_role',
	  method: 'post',
	  data,
	})
}

/**
 * 添加或者修改系统用户信息
 ***/
export function power_save(data) {
	return request({
		url: '/admin/user/adduprole',
		method: 'post',
		data,
	})
}

/**
 * 获取全部的权限信息 不分页
 ***/
export function menu_index(data) {
	return request({
		url: '/admin/user/allpowerlists',
		method: 'post',
		data,
	})
}


